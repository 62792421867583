#custom-badge {
    padding: 10px 20px !important;
    min-width: 100px;
    font-size: 14px !important;
    letter-spacing: 0.6px !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    color: #1F2431;
    background-color: #d9d9d9 !important;
    margin-bottom: 8px;
    font-weight: 400;
}

.large-button {
    padding: 9px 18px !important;
    font-size: 15px !important;
}

ul.p-dropdown-items {
    padding-left: 0 !important;
}

.underline {
    text-decoration: underline !important;
}
.input-container {
    position: relative;
    display: flex;
    align-items: center;
  }
    .search-icon {
    position: absolute;
    right: 10px; /* Adjust based on your preference */
    font-size: 1.2em;
    }

    .search-input {
    padding-left: 35px; /* Space for the magnifier icon */
    width: 100%; /* Adjust width as needed */
    height: 40px; /* Adjust height as needed */
    }


.task-tile {
    width: 90px;
    height: 70px;
    text-align: center;
    border: 1px solid rgba(190, 190, 190, 0.5);
    border-radius: 3px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.task-tile.selected {
    border-color: rgba(190, 190, 190, 0.5);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.tile-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
}

.tile-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.tile-checkmark {
    position: absolute;
    bottom: 5px;
    right: 5px;
    background-color: #28A745;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.tile-label {
    font-size: 14px;
    color: #333;
    margin-top: 5px;
    font-weight: 500;
}

.subtasks-container {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
}

.subtask-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    background-color: #f7f7f7;
    transition: background-color 0.3s ease, transform 0.2s ease;
}
  
.subtask-item:hover {
    background-color: #e7e7e7;
    transform: scale(1.03);
}
  
.task-number {
    font-weight: 600;
    color: #333;
    margin-right: 8px;
}
  
.task-title {
    cursor: pointer;
    color: #007bff;
    text-decoration: underline;
    transition: color 0.3s ease;
}
  
.task-title:hover {
    color: #0056b3;
}

.timeline-entry {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
    display: flex;
}

.timeline-header strong {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
}

.timeline-header span {
    font-size: 14px;
    margin-left: 10px;
    margin-bottom: 5px;
    color: #808080;
}

.timeline-comment p,
.timeline-history p {
    font-size: 13px;
    margin: 0;
}

.timeline-filters {
    padding: 10px;
}










.react-select__menu {
    z-index: 1050 !important;
}

.data-table-container {
    position: relative;
    z-index: 1;
}

.page-title-box {
    padding-bottom: 0 !important;
}
